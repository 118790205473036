import './App.css';
import { Route, Routes } from "react-router-dom";
import React from "react";
import Home from "./pages/home/Home";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";


function App() {

  return (
    <React.Fragment>

    <Navbar />
    <div className='container-fluid grid'>
   <Routes>
    <Route index element={<Home />} />
    <Route path="/home" element={<Home />} />
  </Routes>
  <div className='clear-fix' style={{width:"100%", clear: "both"}}></div>
  
    <Footer />
  </div>

  </React.Fragment>


  )
}

export default App;
