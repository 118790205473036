import React from "react";
import Card from "../../components/card/Card";
import Button from "../../components/button/Button";

const Home = () => {

    let text = <><h5 className='pb-2'>San Francisco, CA (is it on your bucket list?)</h5><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><Button classes='btn-danger my-1 mb-3'>Check it out</Button><p className="text-muted">Lorem ipsum dolor sit amet, quis nostrud exercitation ullamco  consequat.</p></>

    let text2 = <><h3 className='pb-2'>Technology & AI - a tool or foe?</h3><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><Button classes='btn-warning float-end' link='/test'>Learn how to code</Button></>

    let text3 = <><h4 className='pb-2 border-bottom border-secondary text-muted'>Overlanding - traversing the unknown</h4><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><Button classes='btn-info my-2' link='/home'>Beginner introduction</Button><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p></>

    let text4 = <><h3 className='pb-2'>Aquascaping - a hoby that recharges your mind</h3><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><Button classes='btn-success mt-2' link='/blog'>View post</Button></>

    let img = "https://w0.peakpx.com/wallpaper/483/1021/HD-wallpaper-san-francisco-minimalist-city-san-francisco-world-minimalism-minimalist-city-artist-artwork-digital-art.jpg";

    let img2 = "https://www.engineering.columbia.edu/files/seas/styles/816x460/public/content/cs_image/2022/28/robot_hero.jpg?itok=TrT_MOb-";

    let img3 = "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.shtfblog.com%2Fwp-content%2Fuploads%2F2021%2F03%2F15112020-photo-def-1170x658.jpg&f=1&nofb=1&ipt=be523c185189f111f3ea033f19061b1c5875c53fd4b507ea7056e5de4de3c1c0&ipo=images";

    let img4 = "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.ytimg.com%2Fvi%2FjQVZ1jI_XFI%2Fmaxresdefault.jpg&f=1&nofb=1&ipt=3b795d3e4078d32d8a998077b2a3f367823f5c68c18084972981b7ffccd0a6eb&ipo=images";


    // -------------------------------------------------------------
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                <h3 className="border-bottom border-warning pb-2 mt-5">Recent blog posts</h3>
                </div>
            </div>
            <div className="row">
                <div className='col-md-4'>
                    <Card classes="card mt-5 frosty" imgTop={img} footer='Last updated: Feb. 15, 2022'>{text}</Card>
                </div>

                <div className='col-md-8'>
                    <Card classes="card mt-5 frosty" imgBtm={img2} >{text2}</Card>
                </div>
            </div>
            
            
            <div className="row">
                <div className="col-12">
                <h3 className="border-bottom border-warning pb-2 mt-5 content-align-right">How to get into these hobies</h3>
                </div>
            </div>


            <div className="row">
                <div className='col-md-6'>
                    <Card classes="card mt-5 frosty" imgTop={img3} footer='Last updated: Feb. 15, 2022'>{text3}</Card>
                </div>
                <div className='col-md-6'>
                    <Card classes="card mt-5 frosty" imgBtm={img4} >{text4}</Card>
                </div>
                
            </div>

            <div className="row">
                <div className="col-12">
                <h3 className="border-bottom border-warning pb-2 mt-5">Recent blog posts</h3>
                </div>
            </div>
            <div className="row">
                <div className='col-md-4'>
                    <Card classes="card mt-5 frosty" imgTop={img} footer='Last updated: Feb. 15, 2022'>{text}</Card>
                </div>

                <div className='col-md-8'>
                    <Card classes="card mt-5 frosty" imgBtm={img2} >{text2}</Card>
                </div>
            </div>
            
            
            <div className="row">
                <div className="col-12">
                <h3 className="border-bottom border-warning pb-2 mt-5 content-align-right">How to get into these hobies</h3>
                </div>
            </div>


            <div className="row">
                <div className='col-md-6'>
                    <Card classes="card mt-5 frosty" imgTop={img3} footer='Last updated: Feb. 15, 2022'>{text3}</Card>
                </div>
                <div className='col-md-6'>
                    <Card classes="card mt-5 frosty" imgBtm={img4} >{text4}</Card>
                </div>
                
            </div>
            <div className="row">
                <div className="col-12">
                <h3 className="border-bottom border-warning pb-2 mt-5">Recent blog posts</h3>
                </div>
            </div>
            <div className="row">
                <div className='col-md-4'>
                    <Card classes="card mt-5 frosty" imgTop={img} footer='Last updated: Feb. 15, 2022'>{text}</Card>
                </div>

                <div className='col-md-8'>
                    <Card classes="card mt-5 frosty" imgBtm={img2} >{text2}</Card>
                </div>
            </div>
            
            
            <div className="row">
                <div className="col-12">
                <h3 className="border-bottom border-warning pb-2 mt-5 content-align-right">How to get into these hobies</h3>
                </div>
            </div>


            <div className="row">
                <div className='col-md-6'>
                    <Card classes="card mt-5 frosty" imgTop={img3} footer='Last updated: Feb. 15, 2022'>{text3}</Card>
                </div>
                <div className='col-md-6'>
                    <Card classes="card mt-5 frosty" imgBtm={img4} >{text4}</Card>
                </div>
                
            </div>
        </div>
    )
}

export default Home;