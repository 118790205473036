import React from "react";
import "./Footer.css";

const Footer = (props) => {



    return (

        <div className='container-fluid footer frosty py-5 border-top border-dark mt-5'>
            <div className="container">
                <h3>this is where we heal</h3>
            </div>
            
        </div>

    )
}

export default Footer;