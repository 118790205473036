import React from "react";
import "./Card.css";
const Card = (props) => {

    console.log(props);
    // return;
    let cardClasses = "card " + props.classes;
    
    let header = !props.title ? "" : <h4 className='card-header'>{!props.title ? "image" : props.title}</h4>;
    let imgTop = !props.imgTop ? "" : <img className="card-img-top" src={props.imgTop} alt={props.title} />
    let imgBottom = !props.imgBtm ? "" : <img className="card-img-bottom" src={props.imgBtm} alt={!props.title ? "image" : props.title} />
    let footer = !props.footer ? "" : <div className='card-footer'>{props.footer}</div>;
    
    let bodyContent = <div className="card-body">{props.children}</div>;


   return (
    <div class={cardClasses} >
        {header}
        {imgTop}
        {bodyContent}
        {imgBottom}
        {footer}
    </div>
    )
}

export default Card;