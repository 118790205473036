import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";

const Button = (props) => {

    let classes = "btn " + props.classes;
    // If link is part of the props then the link style button will be generated
    // alternatively action / function should be inclided
    
    if(!props.link) { 
        return <button className={classes} onClick={props.action}>{props.children}</button>
    } else {
            return <Link className={classes} to={props.link} >{props.children}</Link>
    }
         
}

export default Button;